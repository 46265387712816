* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: #ffffff;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.container {
  max-width: 133rem !important;
  margin: 0 auto !important;
  padding: 0 2.5rem !important;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #3398db #ffffff;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 10px;
  width: 10px;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:hover {
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:active {
  background-color: #ffffff;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #3398db;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #3398db;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #ff2525;
}

.navbar {
  max-width: 133rem;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.7rem 2rem;
  position: absolute;
  top: 20;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 99999;
  margin: 0 auto;
}

.navbar__img {
  width: 14.5rem;
}

.navbar__img img {
  width: 100%;
  height: 100%;
}

.navbar__links {
  display: flex;
  list-style: none;
  gap: 2.1rem;
}

.navbar__links a {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: #010103;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s;
}

.navbar__links a:hover {
  color: #3398db;
}

@media (max-width: 1000px) {
  .navbar__links {
    display: none;
  }
}

.navbar__buttons {
  visibility: hidden;
  display: flex;
  gap: 2.5rem;
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  align-items: center;
}

@media (max-width: 1000px) {
  .navbar__buttons {
    display: none;
  }
}

.navbar__buttons__sign-in {
  color: #010103;
  cursor: pointer;
  transition: all 0.3s;
}

.navbar__buttons__sign-in:hover {
  color: #3398db;
}

.navbar__buttons__register {
  background-color: #3398db;
  color: white;
  padding: 1.5rem 3rem;
  border-radius: 3px;
  box-shadow: 0 10px 15px 0 rgba(30, 106, 166, 1);
  transition: all 0.3s;
}

.navbar__buttons__register:hover {
  box-shadow: 0 10px 15px 0 rgba(30, 106, 166, 1);
  background-color: #1e6aa6;
}

.navbar__buttons a {
  text-decoration: none;
}

.mobile-hamb {
  font-size: 2.8rem;
  display: none;
  cursor: pointer;
  transition: all 0.3s;
}

.mobile-hamb:hover {
  color: #3398db;
}

@media (max-width: 1000px) {
  .mobile-hamb {
    display: flex;
  }
}

.mobile-navbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -100%;
  background-color: #ffffff;
  z-index: 999999;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.mobile-navbar__close {
  font-size: 3rem;
  position: absolute;
  top: 3.5rem;
  right: 3.5rem;
  cursor: pointer;
  transition: all 0.3s;
}

.mobile-navbar__close:hover {
  color: #3398db;
}

.mobile-navbar__links {
  display: flex;
  flex-direction: column;
  list-style: none;
  font-size: 2.3rem;
  gap: 3rem;
  text-align: center;
}

.mobile-navbar__links li a {
  text-decoration: none;
  color: #010103;
  font-weight: 500;
  transition: all 0.3s;
}

.mobile-navbar__links li a:hover {
  color: #3398db;
}

.open-nav {
  left: 0;
}

.hero-section {
  width: 100%;
  height: 97vh;
  background-color: #f8f8f8;
  position: relative;
}

.bg-shape {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

@media (max-width: 800px) {
  .bg-shape {
    display: none;
  }
}

.hero-content {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}

@media (max-width: 800px) {
  .hero-content {
    justify-content: center;
  }
}

.hero-content__text {
  display: flex;
  flex-direction: column;
  z-index: 3;
  max-width: 50rem;
  margin-top: 5rem;
}

@media (max-width: 800px) {
  .hero-content__text {
    text-align: center;
    align-items: center;
  }
}

.hero-content__text h4 {
  font-size: 2.2rem;
  font-family: "Rubik", sans-serif;
  color: #010103;
}

.hero-content__text h1 {
  font-size: 5.2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #010103;
  line-height: 1.2;
  margin-top: 1rem;
  margin-bottom: 2.3rem;
}

.hero-content__text h1 span {
  color: #3398db;
}

.hero-content__text p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  line-height: 1.6;
  color: #706f7b;
  margin-bottom: 4rem;
}

.hero-content__text__btns {
  display: flex;
  gap: 2rem;
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
}

@media (max-width: 450px) {
  .hero-content__text__btns {
    flex-direction: column;
  }
}

.hero-content__text__btns a {
  text-decoration: none;
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.hero-content__text__btns__book-ride {
  background-color: #3398db;
  padding: 1.8rem 3rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(30, 106, 166, 1);
  transition: all 0.3s;
  border: 2px solid #3398db;
}

@media (max-width: 450px) {
  .hero-content__text__btns__book-ride {
    padding: 1.8rem 8rem;
  }
}

.hero-content__text__btns__book-ride:hover {
  box-shadow: 0 10px 15px 0 rgba(30, 106, 166, 1);
  background-color: #1e6aa6;
}

.hero-content__text__btns__learn-more {
  background-color: #010103;
  padding: 1.8rem 3rem;
  border-radius: 0.3rem;
  border: 2px solid #010103;
  transition: all 0.3s;
}

.hero-content__text__btns__learn-more:hover {
  background-color: transparent;
  color: #010103;
}

.hero-content__car-img {
  z-index: 2;
  position: absolute;
  right: 0;
  width: 65%;
  margin-top: 5rem;
}

@media (max-width: 800px) {
  .hero-content__car-img {
    display: none;
  }
}

.scroll-up {
  position: fixed;
  font-size: 2.5rem;
  color: white;
  background-color: #3398db;
  border: 3px solid white;
  width: 2rem;
  height: 2rem;
  bottom: 5rem;
  right: 5rem;
  z-index: 20;
  display: none;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 2rem;
  cursor: pointer;
}

.show-scroll {
  display: flex;
}

.book-section {
  position: relative;
  background: linear-gradient(to bottom, #f8f8f8 20%, #ffffff 80%);
}

.book-content {
  margin: 0 auto;
  margin-bottom: 10rem;
}

.book-content__box {
  background-color: #ffffff;
  width: 100%;
  padding: 4rem 4.5rem 5rem 5.5rem;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  height: auto;
  position: relative;
  z-index: 4;
  color: white;
  border-radius: 5px;
  background-image: url("/src/images/book-car/book-bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  color: #010103;
}

@media (max-width: 400px) {
  .book-content__box {
    padding: 4rem 2.5rem 5rem 2.5rem;
  }
}

.book-content__box h2 {
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 2.7rem;
}

.book-content__box .box-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 2rem;
}

.book-content__box .box-form b {
  color: #3398db;
}

@media (max-width: 1000px) {
  .book-content__box .box-form {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 630px) {
  .book-content__box .box-form {
    grid-template-columns: 1fr;
  }
}

.book-content__box .box-form__car-type {
  display: flex;
  flex-direction: column;
}

.book-content__box .box-form__car-type label {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
  display: flex;
  align-items: center;
}

.book-content__box .box-form__car-type label i {
  color: #3398db;
}

.book-content__box .box-form__car-type select {
  font-size: 1.5rem;
  color: #ababab;
  font-family: "Rubik", sans-serif;
  border: 1px solid #ccd7e6;
  border-radius: 3px;
  font-weight: 400;
  padding: 1.2rem 1.3rem;
  outline: none;
}

.book-content__box .box-form__car-time {
  display: flex;
  flex-direction: column;
  position: relative;
}

.book-content__box .box-form__car-time input {
  outline: none;
  color: #878585;
  padding-right: 3rem;
  border: 1px solid #ccd7e6;
}

.book-content__box .box-form__car-time label {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
}

.book-content__box .box-form__car-time label i {
  color: #3398db;
}

.book-content__box .box-form__car-time ::-webkit-datetime-edit {
  padding: 1.3rem 1.3rem;
}

.book-content__box .box-form__car-time ::-webkit-calendar-picker-indicator {
  cursor: pointer;
  font-size: 1.6rem;
}

.book-content__box button {
  padding: 1.3rem 1.3rem;
  height: -moz-fit-content;
  height: fit-content;
  align-self: self-end;
  border: none;
  font-family: "Rubik", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  color: white;
  background-color: #3398db;
  box-shadow: 0 10px 15px rgba(30, 106, 166, 1);
  cursor: pointer;
  transition: all 0.3s;
}

.book-content__box button:hover {
  box-shadow: 0 10px 15px rgba(30, 106, 166, 1);
}

.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 1rem 1.4rem;
  margin-bottom: 1.8rem;
  margin-top: -1rem;
  border: 1px solid transparent;
  border-radius: 0.8rem;
  font-size: 1.6rem;
  font-weight: 500;
  display: none;
  justify-content: space-between;
  align-items: center;
}

.error-message i {
  cursor: pointer;
}

.booking-done {
  color: #2a6817;
  background-color: #c3fabe;
  border-color: #f5c6cb;
  padding: 1rem 1.4rem;
  margin-bottom: 1.8rem;
  margin-top: -1rem;
  border: 1px solid transparent;
  border-radius: 0.8rem;
  font-size: 1.6rem;
  font-weight: 500;
  display: none;
  justify-content: space-between;
  align-items: center;
}

.booking-done i {
  cursor: pointer;
}

.active-modal {
  opacity: 1 !important;
  display: flex !important;
}

.modal-overlay {
  opacity: 0;
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999999999;
  top: 0;
  right: 0;
}

.booking-modal {
  opacity: 0;
  display: none;
  flex-direction: column;
  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 999999999999;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 83rem;
  height: 100vh;
  border: 2px solid white;
  background-color: rgb(255, 255, 255);
  padding-right: 2px;
  color: #010103;
}

@media (max-width: 800px) {
  .booking-modal {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .booking-modal {
    top: 50%;
  }
}

.booking-modal__title {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  background-color: #3398db;
  color: white;
  align-items: center;
}

.booking-modal__title h2 {
  font-size: 2.4rem;
  text-transform: uppercase;
}

.booking-modal__title i {
  font-size: 2.5rem;
  color: rgba(255, 255, 255, 0.919);
  cursor: pointer;
  transition: all 0.2;
}

.booking-modal__title i:hover {
  color: white;
}

.booking-modal__message {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 3rem;
  background-color: #ffeae6;
}

.booking-modal__message h4 {
  font-size: 1.9rem;
  font-weight: 700;
  color: #3398db;
}

.booking-modal__message h4 i {
  font-size: 2.5rem;
}

.booking-modal__message p {
  font-size: 1.7rem;
  color: #777777;
  font-weight: 500;
  line-height: 1.6;
}

.booking-modal__car-info {
  background-color: white;
  padding: 3rem 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid rgba(119, 119, 119, 0.6235294118);
}

@media (max-width: 650px) {
  .booking-modal__car-info {
    grid-template-columns: 1fr;
    text-align: center;
  }
}

.booking-modal__car-info__dates {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.booking-modal__car-info__dates h5 {
  font-size: 1.8rem;
  color: #3398db;
}

.booking-modal__car-info__dates span {
  display: flex;
  gap: 1rem;
}

@media (max-width: 650px) {
  .booking-modal__car-info__dates span {
    grid-template-columns: 1fr;
    text-align: center;
    justify-content: center;
  }
}

.booking-modal__car-info__dates span i {
  font-size: 1.8rem;
  padding-top: 0.2rem;
  color: #777777;
}

@media (max-width: 650px) {
  .booking-modal__car-info__dates span i {
    display: none;
  }
}

.booking-modal__car-info__dates span h6 {
  font-size: 1.5rem;
  margin-bottom: 0.2rem;
}

.booking-modal__car-info__dates span p {
  font-size: 1.6rem;
  color: #777777;
}

.booking-modal__car-info__model {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

@media (max-width: 650px) {
  .booking-modal__car-info__model {
    margin-top: 3.5rem;
  }
}

.booking-modal__car-info__model h5 {
  font-size: 1.8rem;
  color: #3398db;
}

.booking-modal__car-info__model h5 span {
  color: #010103;
}

.booking-modal__car-info__model img {
  width: 100%;
  height: auto;
}

.booking-modal__person-info {
  padding: 3rem 3rem;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.booking-modal__person-info h4 {
  font-size: 1.8rem;
  text-transform: uppercase;
  color: #3398db;
  margin-bottom: 2rem;
}

.input-time {
  text-align: center;
  width: 8rem;
  font-size: 14px;
  cursor: pointer;
}

.info-form {
  display: flex;
  flex-direction: column;
}

.info-form__checkbox {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 1.6rem;
  color: #565454;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.info-form__checkbox input {
  width: 1.6rem;
  height: 1.6rem;
}

.info-form__1col {
  grid-template-columns: 1fr !important;
}

.info-form__2col,
.info-form__1col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 1rem 0;
}

@media (max-width: 650px) {

  .info-form__2col,
  .info-form__1col {
    grid-template-columns: 1fr;
  }
}

.info-form__2col span,
.info-form__1col span {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.info-form__2col span label,
.info-form__1col span label {
  font-size: 1.6rem;
  font-weight: 500;
  color: #777777;
}

.info-form__2col span label b,
.info-form__1col span label b {
  color: #3398db;
}

.info-form__2col span input,
.info-form__1col span input {
  padding: 14px 15px;
  background-color: #dbdbdb;
  color: #555;
  font-size: 1.5rem;
  font-weight: 500;
  outline: none;
  border: none;
}

.reserve-button {
  background-color: #dbdbdb;
  margin: 0 -3rem;
  padding: 3rem;
  text-align: right;
}

@media (max-width: 650px) {
  .reserve-button {
    text-align: center;
  }
}

.reserve-button button {
  font-size: 2.4rem;
  color: white;
  font-weight: 700;
  background-color: #3398db;
  border: 1px solid yellow;
  padding: 1.2rem 2rem;
  cursor: pointer;
  transition: all 0.2s;
}

.reserve-button button:hover {
  background-color: #1e6aa6;
}

.dates-div {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
}

.plan-section {
  background-color: #ffffff;
  padding: 5.3rem 0;
}

.plan-container {
  display: flex;
  flex-direction: column;
}

.plan-container__title {
  margin: 0 auto;
  text-align: center;
  color: #010103;
}

.plan-container__title h3 {
  font-size: 2.4rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.plan-container__title h2 {
  font-size: 4.2rem;
  font-family: "Poppins", sans-serif;
  margin: 1.3rem 0 3rem 0;
}

.plan-container__boxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  margin-top: 3.7rem;
  padding: 0 3rem;
}

@media (max-width: 1021px) {
  .plan-container__boxes {
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
  }
}

@media (max-width: 800px) {
  .plan-container__boxes {
    grid-template-columns: 1fr;
    margin-top: 1rem;
  }
}

.plan-container__boxes__box {
  text-align: center;
  padding: 1rem 6rem;
}

@media (max-width: 500px) {
  .plan-container__boxes__box {
    padding: 1rem 1rem;
  }
}

.plan-container__boxes__box img {
  width: 17rem;
  height: auto;
}

.plan-container__boxes__box h3 {
  font-size: 2.4rem;
  margin-bottom: 1rem;
}

.plan-container__boxes__box p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #706f7b;
  line-height: 1.43;
}

.pick-section {
  padding: 10rem 0;
}

.pick-container {
  display: flex;
  flex-direction: column;
}

.pick-container__title {
  margin: 0 auto;
  text-align: center;
  color: #010103;
  max-width: 50rem;
  margin-bottom: 5rem;
}

.pick-container__title p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #706f7b;
  line-height: 1.5;
}

.pick-container__title h3 {
  font-size: 2.4rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.pick-container__title h2 {
  font-size: 4.2rem;
  font-family: "Poppins", sans-serif;
  margin: 0.5rem 0 1rem 0;
}

.pick-container__car-content {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

@media (max-width: 1050px) {
  .pick-container__car-content {
    flex-direction: column;
    gap: 5rem;
  }
}

/* Estilos para el combobox */
.pick-box select {
  font-size: 2rem;
  /* Tamaño de letra más grande */
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  border: none;
  cursor: pointer;
  padding: 1.5rem 2.5rem;
  background-color: #e9e9e9;
  transition: background-color 0.2s;
  text-align: left;
  appearance: none;
  width: 100%;
  max-width: 25rem;
  height: 3.5rem;
}

.pick-box select:hover {
  background-color: #d3d3d3;
}

.pick-box {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.pick-box button {
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  border: none;
  cursor: pointer;
  padding: 1.5rem 2.5rem;
  background-color: #e9e9e9;
  transition: all 0.2s;
  text-align: left;
}

.pick-box button:hover {
  background-color: #3398db;
  color: white;
}

.pick-car {
  width: 50rem;
  position: relative;
}

@media (max-width: 700px) {
  .pick-car {
    width: 100%;
  }
}

.pick-car img {
  width: 100%;
  margin-top: 6rem;
}

.pick-description {
  width: 25rem;
}

.pick-description__price {
  width: 100%;
  background-color: #3398db;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  color: white;
  padding: 0.3rem 1.9rem;
  white-space: nowrap;
}

.pick-description__price span {
  font-size: 2.8rem;
  font-weight: 700;
}

.pick-description__table {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  font-size: 1.4rem;
}

.pick-description__table__col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  grid-template-rows: auto;
  padding: 0.9rem 0.5rem;
  border-bottom: 2px solid #706f7b;
  border-right: 2px solid #706f7b;
  border-left: 2px solid #706f7b;
}

.pick-description__table__col span:nth-child(1) {
  border-right: 2px solid #706f7b;
}

.cta-btn {
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  color: white;
  font-family: "Poppins", sans-serif;
  background-color: #3398db;
  padding: 1rem 1rem;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1.4rem;
  transition: all 0.3s;
  box-shadow: 6px 6px 0 #efe9e9;
}

.cta-btn:hover {
  background-color: #1e6aa6;
}

.box-cars {
  gap: 11rem;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .box-cars {
    gap: 2rem;
  }
}

@media (max-width: 700px) {
  .box-cars {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
  }
}

.colored-button {
  background-color: #3398db !important;
  color: white;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid rgba(0, 0, 0, 0.2588235294);
  border-bottom-color: #1e6aa6;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 40%;
  right: 43%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.banner-section {
  width: 100%;
  height: auto;
  display: flex;
  background-color: #2d2d2d;
  margin: 8rem 0;
  padding: 6rem 0;
  text-align: center;
}

.banner-content {
  color: white;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
}

.banner-content__text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.banner-content__text h2 {
  font-size: 5.2rem;
  line-height: 1.3;
}

@media (max-width: 550px) {
  .banner-content__text h2 {
    font-size: 4.2rem;
  }
}

.banner-content__text p {
  font-size: 2.4rem;
}

@media (max-width: 550px) {
  .banner-content__text p {
    font-size: 2rem;
  }
}

.banner-content__text span {
  color: #3398db;
  font-weight: 500;
}

.choose-section {
  background-color: white;
  padding: 2rem 0 10rem 0;
  background-image: url("/src/images/chooseUs/bg.png");
  background-position: -225px 255px;
  background-size: cover;
  background-repeat: no-repeat;
}

.choose-container {
  display: flex;
  flex-direction: column;
}

.choose-container__img {
  width: 90%;
  height: auto;
  margin: 0 auto;
}

@media (max-width: 550px) {
  .choose-container__img {
    width: 100%;
  }
}

.text-container {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
  width: 100%;
}

@media (max-width: 1000px) {
  .text-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5.5rem;
  }
}

.text-container__left {
  text-align: left;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  color: #010103;
}

@media (max-width: 1000px) {
  .text-container__left {
    align-items: center;
    text-align: center;
  }
}

.text-container__left h4 {
  font-size: 2.2rem;
  margin-bottom: 0.7rem;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
}

.text-container__left h2 {
  font-size: 4.2rem;
  line-height: 1.2;
  margin-bottom: 2rem;
}

.text-container__left p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
  color: #706f7b;
  margin-bottom: 3.3rem;
}

.text-container__left a {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #3398db;
  padding: 1.5rem 2.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(30, 106, 166, 1);
  transition: all 0.3s;
  border: 2px solid #3398db;
  font-size: 1.6rem;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

.text-container__left a:hover {
  box-shadow: 0 10px 15px 0 rgba(30, 106, 166, 1);
  background-color: #1e6aa6;
}

.text-container__right {
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
  max-width: 44rem;
}

.text-container__right__box {
  display: flex;
}

@media (max-width: 550px) {
  .text-container__right__box {
    flex-direction: column;
    align-items: center;
  }
}

.text-container__right__box img {
  width: 11rem;
  height: 11rem;
  margin-right: 1.1rem;
}

.text-container__right__box__text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.text-container__right__box__text h4 {
  font-size: 2.4rem;
}

.text-container__right__box__text p {
  font-size: 1.6rem;
  color: #706f7b;
  font-family: "Rubik", sans-serif;
  line-height: 1.3;
}

.testimonials-section {
  background-color: #f8f8f8;
  padding: 10rem 0;
  color: #010103;
}

.testimonials-content {
  display: flex;
  flex-direction: column;
}

.testimonials-content__title {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  max-width: 70rem;
  margin-bottom: 5rem;
}

.testimonials-content__title h4 {
  font-size: 2.2rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.testimonials-content__title h2 {
  font-size: 4.2rem;
  margin-bottom: 1.4rem;
}

.testimonials-content__title p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #706f7b;
  line-height: 1.4;
}

.all-testimonials {
  display: flex;
  gap: 3rem;
  width: 100%;
  justify-content: center;
  padding: 3rem;
}

@media (max-width: 1000px) {
  .all-testimonials {
    padding: 0;
  }
}

.all-testimonials__box {
  background-color: white;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.08);
  width: 54rem;
  padding: 5.5rem;
  position: relative;
}

@media (max-width: 1000px) {
  .all-testimonials__box {
    padding: 5rem 3rem;
  }
}

.all-testimonials__box p {
  font-size: 2.2rem;
  font-weight: 500;
}

.all-testimonials__box__name {
  display: flex;
}

.all-testimonials__box__name__profile {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-top: 3rem;
}

.all-testimonials__box__name__profile img {
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
}

.all-testimonials__box__name__profile h4 {
  font-size: 1.8rem;
}

.all-testimonials__box__name__profile p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

.quotes-icon {
  font-size: 6.2rem;
  color: #3398db;
  position: absolute;
  bottom: 33px;
  right: 60px;
}

@media (max-width: 470px) {
  .quotes-icon {
    display: none;
  }
}

@media (max-width: 900px) {
  .box-2 {
    display: none;
  }
}

.faq-section {
  background-image: url("");
  padding: 10rem 0;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 0 70%;
}

.faq-content {
  display: flex;
  flex-direction: column;
  color: #010103;
}

.faq-content__title {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  max-width: 80rem;
  line-height: 1.5;
}

.faq-content__title h5 {
  font-size: 2.2rem;
}

.faq-content__title h2 {
  font-size: 4.2rem;
  margin-bottom: 1.7rem;
}

.faq-content__title p {
  font-size: 1.6rem;
  color: #706f7b;
}

.all-questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7rem;
}

.faq-box {
  display: flex;
  flex-direction: column;
  color: #010103;
  background-color: white;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  width: 80rem;
  cursor: pointer;
}

@media (max-width: 850px) {
  .faq-box {
    width: 100%;
  }
}

.faq-box__question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 1.8rem 4.5rem;
  transition: 0.15s ease;
}

.faq-box__question p {
  font-size: 1.8rem;
  font-weight: 500;
}

.faq-box__question i {
  font-size: 2rem;
}

.faq-box__answer {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #706f7b;
  line-height: 1.7;
  max-height: 0;
  overflow: hidden;
  transition: 0.4s ease;
  padding: 0 4.5rem;
}

.active-answer {
  max-height: 20rem;
  padding: 2.8rem 4.5rem;
  transition: 0.4s ease;
}

@media (max-width: 550px) {
  .active-answer {
    max-height: 30rem;
  }
}

@media (max-width: 420px) {
  .active-answer {
    max-height: 55rem;
  }
}

.active-question {
  background-color: #3398db;
  color: white;
  box-shadow: 0 10px 15px 0 rgba(30, 106, 166, 1);
}

.download-section {
  background-image: url("/src/images/banners/bg02.png");
  background-color: #f8f8f8;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  padding: 10rem 0;
}

@media (max-width: 700px) {
  .download-section {
    background-image: none;
  }
}

.download-text {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 55rem;
  text-align: left;
}

@media (max-width: 700px) {
  .download-text {
    text-align: center;
    margin: 0 auto;
  }
}

.download-text h2 {
  font-size: 4.2rem;
  color: #010103;
}

.download-text p {
  font-size: 1.6rem;
  color: #706f7b;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
}

.download-text__btns {
  display: flex;
  gap: 3rem;
  margin-top: 2rem;
}

@media (max-width: 700px) {
  .download-text__btns {
    justify-content: center;
  }
}

@media (max-width: 550px) {
  .download-text__btns {
    flex-direction: column;
    align-items: center;
  }
}

.download-text__btns img {
  width: 40%;
  cursor: pointer;
}

@media (max-width: 550px) {
  .download-text__btns img {
    width: 22rem;
  }
}

footer {
  background-color: #ffffff;
  padding: 10rem 0;
}

.footer-content {
  display: grid;
  color: #010103;
  grid-template-columns: 27fr 21fr 21fr 21fr;
  gap: 8rem;
  justify-content: center;
  text-align: left;
  color: #010103;
}

@media (max-width: 1100px) {
  .footer-content {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 650px) {
  .footer-content {
    grid-template-columns: 1fr;
  }
}

.footer-content__1 {
  list-style: none;
}

@media (max-width: 650px) {
  .footer-content__1 {
    text-align: center;
  }
}

.footer-content__1 li a {
  text-decoration: none;
  color: #010103;
  transition: all 0.2s;
}

.footer-content__1 li a:hover {
  color: #3398db;
}

.footer-content__1 li:nth-child(1) {
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
}

.footer-content__1 li:nth-child(1) span {
  font-weight: 700;
}

.footer-content__1 li:nth-child(2) {
  font-size: 1.6rem;
  color: #706f7b;
  line-height: 1.7;
  margin-bottom: 3rem;
}

.footer-content__1 li:nth-child(3),
.footer-content__1 li:nth-child(4) {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
}

.footer-content__2 {
  list-style: none;
}

@media (max-width: 650px) {
  .footer-content__2 {
    text-align: center;
  }
}

.footer-content__2 a {
  text-decoration: none;
  color: #010103;
  transition: all 0.2s;
}

.footer-content__2 a:hover {
  color: #3398db;
}

.footer-content__2 li {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.footer-content__2 li:nth-child(1) {
  font-size: 2.4rem;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  cursor: auto;
}

.footer-content__2 input {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  background-color: #ececec;
  border-width: 1px;
  border-color: transparent;
  padding: 10px 60px;
  outline: none;
  margin-top: 1rem;
}

@media (max-width: 1100px) {
  .footer-content__2 input {
    width: 100%;
  }
}

.submit-email {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #3398db;
  padding: 1.5rem 2.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(30, 106, 166, 1);
  transition: all 0.3s;
  border: 2px solid #3398db;
  font-size: 1.6rem;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  width: 100%;
}

.submit-email:hover {
  box-shadow: 0 10px 15px 0 rgba(30, 106, 166, 1);
  background-color: #1e6aa6;
}

.hero-pages {
  width: 100%;
  height: 26rem;
  background-image: url("/src/images/hero/heroes-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-pages__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.92);
}

.hero-pages__text {
  display: flex;
  flex-direction: column;
  z-index: 3;
  position: relative;
  width: 100%;
  height: 41rem;
  justify-content: center;
  color: #010103;
}

.hero-pages__text h3 {
  font-size: 3.6rem;
  margin-bottom: 0.5rem;
}

.hero-pages__text p {
  font-size: 1.6rem;
  font-weight: 600;
}

.hero-pages__text p a {
  color: #010103;
  text-decoration: none;
  transition: all 0.2s;
}

.hero-pages__text p a:hover {
  color: #3398db;
}

.about-main {
  margin: 10rem auto;
  display: flex;
  gap: 5rem;
  max-width: 90rem;
  align-items: center;
}

@media (max-width: 960px) {
  .about-main {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    max-width: 49rem;
  }
}

@media (max-width: 520px) {
  .about-main {
    max-width: 100%;
  }
}

.about-main__img {
  width: 43rem;
  height: 43rem;
}

@media (max-width: 960px) {
  .about-main__img {
    margin: 0 auto;
  }
}

@media (max-width: 520px) {
  .about-main__img {
    width: 100%;
    height: auto;
  }
}

.about-main__text {
  color: #010103;
  display: flex;
  flex-direction: column;
}

.about-main__text h3 {
  font-size: 2.2rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
}

.about-main__text h2 {
  font-size: 4.2rem;
  line-height: 1.2;
}

.about-main__text p {
  font-size: 1.6rem;
  color: #706f7b;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.about-main__text__icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4rem;
}

@media (max-width: 520px) {
  .about-main__text__icons {
    grid-template-columns: 1fr;
    margin: 0 auto;
    gap: 0;
  }

  .about-main__text__icons p {
    margin-top: 0;
  }
}

.about-main__text__icons__box {
  display: flex;
  flex-direction: column;
}

@media (max-width: 520px) {
  .about-main__text__icons__box {
    align-items: center;
  }
}

.about-main__text__icons__box .last-fk {
  width: 5rem;
}

.about-main__text__icons__box img {
  width: 7rem;
}

.about-main__text__icons__box span {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 520px) {
  .about-main__text__icons__box span {
    text-align: center;
    flex-direction: column;
  }
}

.about-main__text__icons__box span h4 {
  font-size: 4.6rem;
}

.book-banner {
  display: flex;
  width: 100%;
  height: 20rem;
  background-image: url("/src/images/banners/book-banner.png");
  position: relative;
  margin-top: 7rem;
}

@media (max-width: 750px) {
  .book-banner {
    height: -moz-fit-content;
    height: fit-content;
    padding: 1rem 0;
  }
}

.book-banner__overlay {
  background-color: #2d2d2d;
  opacity: 0.89;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.text-content {
  color: white;
  z-index: 5;
  position: relative;
  width: 100%;
  height: 20rem;
  display: flex;
  align-items: center;
  gap: 5rem;
}

@media (max-width: 750px) {
  .text-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-align: center;
  }
}

.text-content h2 {
  font-size: 3.2rem;
}

.text-content span {
  display: flex;
  font-size: 2.7rem;
  gap: 1rem;
  align-items: center;
  color: #3398db;
  white-space: nowrap;
}

.models-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  align-items: start;
  text-align: center;
  padding: 3% 0;
  margin: 0 auto;
  width: auto;
  max-width: 1200px; /* Max width can be adjusted according to design requirements */
}

@media (max-width: 1150px) {
  .models-div {
    grid-template-columns: 1fr 1fr;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media (max-width: 800px) {
  .models-div {
    grid-template-columns: 1fr;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.models-div__box {
  border: 1px solid #d5d5d5;
  padding: 2rem;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff; /* Adds a subtle background color */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Soft shadow for depth */
  transition: transform 0.3s ease-in-out; /* Smooth transition for hover effects */
  height: 100%; /* Ensures all boxes are of the same height */
}

.models-div__box:hover {
  transform: translateY(-5px); /* Slight raise effect on hover */
}


@media (max-width: 400px) {
  .models-div__box {
    grid-template-columns: 1fr;
    width: 100%;
  }
}

.models-div__box__img {
  width: 100%;
  height: auto;
  border-radius: 0.3rem;
  overflow: hidden; /* Prevents image overflow */
  justify-content: center;
  align-items: center;
  display: contents;
}

.models-div__box__img img {
  width: 100%;
  height: auto;
  border-radius: 0.3rem;
}

.models-div__box__descr {
  background-color: #ffffff; /* Fondo blanco para coincidir con el diseño de la tarjeta */
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem; /* Espaciado uniforme entre elementos */
}

.models-div__box__descr__name-price {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Alinea verticalmente el nombre y el precio */
}


.models-div__box__descr__name-price__name,
.models-div__box__descr__name-price__price {
  flex: 1; /* Distribuye el espacio disponible por igual */
}

.models-div__box__descr__name-price__name {
  align-items: flex-start; /* Alinea el nombre a la izquierda */
}

.models-div__box__descr__name-price__name p:last-child {
  font-size: 1.2rem; /* Tamaño más pequeño para 'OR SIMILAR' */
  color: #777;
}

.models-div__box__descr__name-price__name p:first-child {
  font-size: 1.8rem; /* Resalta el nombre del modelo */
  font-weight: 600;
  color: #333;
}

.models-div__box__descr__name-price__name p,
.models-div__box__descr__name-price__price h4,
.models-div__box__descr__name-price__price p {
  margin: 0.5rem 0; /* Consistent margin for all text elements */
}

.models-div__box__descr__name-price__name p {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.models-div__box__descr__name-price__name span {
  justify-content: flex-start; /* Alinea las estrellas a la izquierda */
}

.models-div__box__descr__name-price__name span i {
  font-size: 1.4rem;
  color: #ffc933;
}

.models-div__box__descr__name-price__price {
  flex-shrink: 0; /* Mantiene el bloque del precio sin deformarse */
  text-align: right;
  min-width: 120px; /* Ancho mínimo para consistencia visual */
}

.models-div__box__descr__name-price__price h4 {
  font-size: 2.8rem;
}

.models-div__box__descr__name-price__price p {
  font-size: 1.6rem;
}

.models-div__box__descr__name-price__details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center; /* Centra los elementos en sus columnas respectivas */
  gap: 1rem; /* Aumenta el espaciado si es necesario */
  font-size: 1.4rem;
  color: #666;
}

.models-div__box__descr__name-price__details span:first-child,
.models-div__box__descr__name-price__details span:nth-child(odd) {
  justify-self: end; /* Alinea el primer y tercer elemento hacia la derecha */
}

.models-div__box__descr__name-price__details span {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Alineación al inicio para el texto a la izquierda */
}

.models-div__box__descr__name-price__details span:nth-child(even) {
  justify-self: start; /* Alinea el segundo y cuarto elemento hacia la izquierda */
}

.models-div__box__descr__name-price__details span i {
  color: #010103;
}

.models-div__box__descr__name-price__btn {
  width: 100%; /* El botón se extiende por todo el ancho del contenedor */
  padding: 1.2rem 0;
  font-size: 1.2rem;
  background-color: #5cb85c; /* Color visible en todo momento */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.models-div__box__descr__name-price__btn:hover {
  background-color: #4cae4c; /* Oscurece el botón al pasar el ratón */
}

.models-div__box__descr__name-price__btn a {
  text-decoration: none;
  color: inherit; /* Use the button's text color */
}

.team-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 4rem;
  align-items: center;
  text-align: center;
  padding: 10rem 2rem;
  width: 110rem;
  margin: 0 auto;
}

@media (max-width: 1150px) {
  .team-container {
    grid-template-columns: 1fr 1fr;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media (max-width: 800px) {
  .team-container {
    grid-template-columns: 1fr;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.team-container__box {
  width: 33rem;
  background-color: white;
  box-shadow: 0px 20px 10px 0px rgba(0, 0, 0, 0.08);
}

@media (max-width: 400px) {
  .team-container__box {
    width: 100%;
  }
}

.team-container__box__img-div {
  width: 100%;
  height: auto;
  background-color: #f6f6f6;
}

.team-container__box__img-div img {
  width: 100%;
}

.team-container__box__descr {
  color: #010103;
  padding: 3rem;
}

.team-container__box__descr h3 {
  font-size: 2.2rem;
}

.team-container__box__descr p {
  font-size: 1.6rem;
  font-weight: 500;
  color: #777777;
}

.contact-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin: 0 auto;
  color: #010103;
  padding: 10rem 2rem;
  background-image: url("/src/images/banners/bg-contact.png");
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;
}

@media (max-width: 950px) {
  .contact-div {
    grid-template-columns: 1fr;
    text-align: center;
  }
}

.contact-div__text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 41rem;
}

@media (max-width: 950px) {
  .contact-div__text {
    margin: 0 auto;
    margin-bottom: 2rem;
  }
}

.contact-div__text h2 {
  font-size: 4.2rem;
  line-height: 1.3;
  margin-bottom: 2rem;
}

.contact-div__text p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #706f7b;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.contact-div__text a {
  text-decoration: none;
  color: #010103;
  font-size: 1.6rem;
  font-weight: 500;
  transition: all 0.2s;
  margin-bottom: 0.5rem;
}

.contact-div__text a:hover {
  color: #3398db;
}

.contact-div__form {
  display: flex;
  flex-direction: column;
}

.contact-div__form form {
  display: flex;
  flex-direction: column;
}

.contact-div__form form label {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.contact-div__form form label b {
  color: #3398db;
}

.contact-div__form form input {
  background-color: #f2f2f2;
  padding: 19px 30px 19px 30px;
  font-size: 1.6rem;
  border: none;
  outline: none;
  margin-bottom: 2.3rem;
}

.contact-div__form form textarea {
  background-color: #f2f2f2;
  height: 18rem;
  padding: 19px 30px 19px 30px;
  font-size: 1.6rem;
  border: none;
  outline: none;
  margin-bottom: 2.5rem;
}

.contact-div__form form button {
  background-color: #3398db;
  padding: 1.8rem 3rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(30, 106, 166, 1);
  transition: all 0.3s;
  border: 2px solid #3398db;
  color: white;
  font-size: 1.8rem;
  font-weight: 600;
  cursor: pointer;
}

.contact-div__form form button:hover {
  box-shadow: 0 10px 15px 0 rgba(30, 106, 166, 1);
  background-color: #1e6aa6;
}

/*Boton WhatsApp*/
.btn-wsp {
  position: fixed;
  width: 60px;
  height: 60px;
  line-height: 63px;
  bottom: 115px;
  right: 42px;
  background: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 35px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
  z-index: 10000;
  /* Ajusta el valor del z-index según sea necesario */
  transition: all 300ms ease;
}

.btn-wsp:hover {
  background: #20ba5a;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .btn-wsp {
    width: 63px;
    height: 63px;
    line-height: 66px;
  }

  .btn-wsp {
    position: fixed;
    width: 60px;
    height: 60px;
    line-height: 63px;
    bottom: 115px;
    right: 22px;
    background: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 35px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
    z-index: 10000;
    transition: all 300ms ease;
  }
}

/* carousel */
.carousel {
  margin-top: 150px;
  margin-bottom: 30px;
  height: 95%;
  width: 100%;
  margin-top: -50px;
  overflow: hidden;
  position: relative;
}

.carousel .list .item {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0 0 0 0;
}

.carousel .list .item img {
  margin-top: 170px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel .list .item .content {
  position: absolute;
  top: 25%;
  width: 1140px;
  max-width: 80%;
  left: 50%;
  transform: translateX(-50%);
  padding-right: 30%;
  box-sizing: border-box;
  color: #fff;
  text-shadow: 0 5px 10px #0004;
}

.carousel .list .item .author {
  font-weight: bold;
  letter-spacing: 10px;
  font-size: 2rem;
}

.carousel .list .item .title,
.carousel .list .item .topic {
  font-size: 7em;
  font-weight: bold;
  line-height: 1.3em;
}

.carousel .list .item .topic {
  color: #f1683a;
}

.carousel .list .item .buttons {
  display: grid;
  grid-template-columns: repeat(2, 130px);
  grid-template-rows: 40px;
  gap: 5px;
  margin-top: 20px;
}

.carousel .list .item .buttons button {
  border: none;
  background-color: #eee;
  letter-spacing: 3px;
  font-family: Poppins;
  font-weight: 700;
}

.carousel .list .item .buttons button:nth-child(2) {
  background-color: transparent;
  border: 1px solid #fff;
  color: #eee;
}

.carousel .list .item .des {
  font-size: 24px;
  /* Cambia el tamaño de la fuente según tus preferencias */
}

/* thumbail */
.thumbnail {
  position: absolute;
  bottom: 50px;
  left: 50%;
  width: max-content;
  z-index: 100;
  display: flex;
  gap: 20px;
}

.thumbnail .item {
  width: 150px;
  height: 220px;
  flex-shrink: 0;
  position: relative;
}

.thumbnail .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.thumbnail .item .content {
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
}

.thumbnail .item .content .title {
  font-weight: 500;
}

.thumbnail .item .content .description {
  font-weight: 400;
}

/* arrows */
.arrows {
  position: absolute;
  top: 80%;
  right: 52%;
  z-index: 100;
  width: 300px;
  max-width: 30%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.arrows button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #eee4;
  border: none;
  color: #fff;
  font-family: monospace;
  font-weight: bold;
  transition: 0.5s;
}

.arrows button:hover {
  background-color: #fff;
  color: #000;
}

/* animation */
.carousel .list .item:nth-child(1) {
  z-index: 1;
}

/* animation text in first item */

.carousel .list .item:nth-child(1) .content .author,
.carousel .list .item:nth-child(1) .content .title,
.carousel .list .item:nth-child(1) .content .topic,
.carousel .list .item:nth-child(1) .content .des,
.carousel .list .item:nth-child(1) .content .buttons {
  transform: translateY(50px);
  filter: blur(20px);
  opacity: 0;
  animation: showContent 0.5s 1s linear 1 forwards;
}

@keyframes showContent {
  to {
    transform: translateY(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.carousel .list .item:nth-child(1) .content .title {
  animation-delay: 1.2s !important;
}

.carousel .list .item:nth-child(1) .content .topic {
  animation-delay: 1.4s !important;
}

.carousel .list .item:nth-child(1) .content .des {
  animation-delay: 1.6s !important;
}

.carousel .list .item:nth-child(1) .content .buttons {
  animation-delay: 1.8s !important;
}

/* create animation when next click */
.carousel.next .list .item:nth-child(1) img {
  margin-top: 170px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  bottom: 50px;
  left: 50%;
  border-radius: 30px;
  animation: showImage 0.5s linear 1 forwards;
}

@keyframes showImage {
  to {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.carousel.next .thumbnail .item:nth-last-child(1) {
  overflow: hidden;
  animation: showThumbnail 0.5s linear 1 forwards;
}

.carousel.prev .list .item img {
  z-index: 100;
}

@keyframes showThumbnail {
  from {
    width: 0;
    opacity: 0;
  }
}

.carousel.next .thumbnail {
  animation: effectNext 0.5s linear 1 forwards;
}

@keyframes effectNext {
  from {
    transform: translateX(150px);
  }
}

/* running time */

.carousel.next .time,
.carousel.prev .time {
  animation: runningTime 3s linear 1 forwards;
}

@keyframes runningTime {
  from {
    width: 100%;
  }

  to {
    width: 0;
  }
}

/* prev click */

.carousel.prev .list .item:nth-child(2) {
  z-index: 2;
}

.carousel.prev .list .item:nth-child(2) img {
  animation: outFrame 0.5s linear 1 forwards;
  position: absolute;
  bottom: 0;
  left: 0;
}

@keyframes outFrame {
  to {
    width: 150px;
    height: 220px;
    bottom: 50px;
    left: 50%;
    border-radius: 20px;
  }
}

.carousel.prev .thumbnail .item:nth-child(1) {
  overflow: hidden;
  opacity: 0;
  animation: showThumbnail 0.5s linear 1 forwards;
}

.carousel.next .arrows button,
.carousel.prev .arrows button {
  pointer-events: none;
}

.carousel.prev .list .item:nth-child(2) .content .author,
.carousel.prev .list .item:nth-child(2) .content .title,
.carousel.prev .list .item:nth-child(2) .content .topic,
.carousel.prev .list .item:nth-child(2) .content .des,
.carousel.prev .list .item:nth-child(2) .content .buttons {
  animation: contentOut 1.5s linear 1 forwards !important;
}

@keyframes contentOut {
  to {
    transform: translateY(-150px);
    filter: blur(20px);
    opacity: 0;
  }
}

/* media queries */
@media screen and (max-width: 678px) {
  .carousel .list .item .content {
    padding-right: 0;
  }

  .carousel .list .item .content .title {
    font-size: 50px;
  }
}

@media (max-width: 991px) {
  html {
    font-size: 55%;
  }

  .header {
    padding: 2rem;
  }

  .header.active {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  #menu-btn {
    display: block;
  }

  #menu-btn.fa-times {
    transform: rotate(180deg);
  }

  .header #login-btn i {
    display: block;
  }

  .header #login-btn .btn {
    display: none;
  }

  .header .navbar.active {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  .header .navbar a {
    display: block;
    margin: 2rem;
    font-size: 2rem;
  }

  .carousel {
    margin-top: 100px;
    /* Agregado un margen superior para dar espacio al navbar */
  }
}

@media (max-width: 600px) {
  .carousel .list .item img {
    margin-top: 40px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .carousel.next .list .item:nth-child(1) img {
    margin-top: 40px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    bottom: 50px;
    left: 50%;
    border-radius: 30px;
    animation: showImage 0.5s linear 1 forwards;
  }


  /* Estilos adicionales para tablets en modo vertical */
}

@media (max-width: 500px) {
  html {
    font-size: 50%;
  }

  .heading {
    font-size: 3rem;
  }

  .carousel .list .item img {
    margin-top: 30px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .carousel.next .list .item:nth-child(1) img {
    margin-top: 30px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    bottom: 50px;
    left: 50%;
    border-radius: 30px;
    animation: showImage 0.5s linear 1 forwards;
  }

  .navbar {
    max-width: 133rem;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.7rem 2rem;
    position: absolute;
    top: -20;
    left: 0;
    right: 0;
    background-color: transparent;
    z-index: 99999;
    margin: 0 auto;
  }

  .navbar__img img {
    width: 150px;
    height: 150px;
  }

}

@media (max-width: 360px) {

  .navbar {
    max-width: 133rem;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.7rem 2rem;
    position: absolute;
    top: -20;
    left: 0;
    right: 0;
    background-color: transparent;
    z-index: 99999;
    margin: 0 auto;
  }

  .navbar__img img {
    width: 150px;
    height: 150px;
  }

  .carousel .list .item .author {
    margin-top: -70px;
    font-weight: bold;
    letter-spacing: 10px;
    font-size: 2rem;
  }

  .carousel .list .item .content .title {
    font-size: 35px;
  }

  .carousel .list .item .title,
  .carousel .list .item .topic {
    font-size: 5em;
    font-weight: bold;
    line-height: 1.3em;
  }

  .carousel .list .item .des {
    font-size: 16px;
  }

  /* Estilos adicionales para dispositivos muy pequeños */
}

@media only screen and (max-width: 375px) {


  .navbar {
    max-width: 133rem;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.7rem 2rem;
    position: absolute;
    top: -20;
    left: 0;
    right: 0;
    background-color: transparent;
    z-index: 99999;
    margin: 0 auto;
  }

  .navbar__img img {
    width: 150px;
    height: 150px;
  }

  .carousel .list .item .author {
    margin-top: -70px;
    font-weight: bold;
    letter-spacing: 10px;
    font-size: 2rem;
  }

  .carousel .list .item .content .title {
    font-size: 30px;
  }

  .carousel .list .item .title,
  .carousel .list .item .topic {
    font-size: 4em;
    font-weight: bold;
    line-height: 1.3em;
  }

  .carousel .list .item .des {
    font-size: 17px;
  }

  /* Estilos para dispositivos con pantalla de 320px de ancho o menos */
  /* Puedes agregar aquí tus reglas de estilo adicionales */
}

@media only screen and (max-width: 390px) {

  .navbar {
    max-width: 133rem;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.7rem 2rem;
    position: absolute;
    top: -10;
    left: 0;
    right: 0;
    background-color: transparent;
    z-index: 99999;
    margin: 0 auto;
  }

  .navbar__img img {
    width: 120px;
    height: 120px;
  }

  .carousel .list .item .author {
    margin-top: -80px;
    font-weight: bold;
    letter-spacing: 10px;
    font-size: 2rem;
  }

  .carousel .list .item .content .title {
    font-size: 40px;
  }

  .carousel .list .item .title,
  .carousel .list .item .topic {
    font-size: 6em;
    font-weight: bold;
    line-height: 1.3em;
  }

  .carousel .list .item .des {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1366px) {
  .navbar {
    max-width: 133rem;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.7rem 2rem;
    position: absolute;
    top: -2;
    left: 0;
    right: 0;
    background-color: transparent;
    z-index: 99999;
    margin: 0 auto;
  }

  .carousel .list .item .title,
  .carousel .list .item .topic {
    font-size: 5em;
    font-weight: bold;
    line-height: 1.3em;
  }

  .carousel .list .item .des {
    font-size: 16px;
  }

  .carousel .list .item .content {
    position: absolute;
    top: 38%;
    width: 1140px;
    max-width: 80%;
    left: 50%;
    transform: translateX(-50%);
    padding-right: 30%;
    box-sizing: border-box;
    color: #fff;
    text-shadow: 0 5px 10px #0004;
  }


  .thumbnail {
    position: absolute;
    bottom: 50px;
    left: 63%;
    width: max-content;
    z-index: 100;
    display: flex;
    gap: 20px;
  }

  .carousel {
    margin-top: 170px;
    margin-bottom: 30px;
    height: 110vh;
    width: 100%;
    margin-top: -50px;
    overflow: hidden;
    position: relative;
  }

  .carousel .list .item img {
    margin-top: 170px;
    /* Esto puede ajustarse según sea necesario */
    width: 100vw;
    /* Ancho al 100% del ancho de la ventana */
    height: 100vh;
    /* Alto al 100% del alto de la ventana */
    object-fit: cover;
    /* Para cubrir todo el espacio sin distorsionar la imagen */
  }

  .pick-section {
    padding: 5rem 0;
  }
}


/* We can meke it easy! */
.p_nav_uwu {
  padding: -90px;
  margin: 0 auto;
  font-family: 'Poppins';
  font-size: 20px;
  color: red;
  font-weight: bold;
}

.to_p_nav_uwu {
  color: #3398db;
}

.booking-modal__optional-coverages h4 {
  font-size: 1.9rem;
  /* Reducido ligeramente */
  text-transform: uppercase;
  color: #3398db;
  margin-bottom: 1.8rem;
  /* Reducido ligeramente */
}

.booking-modal__optional-coverages .option {
  margin-bottom: 0.8rem;
  /* Reducido ligeramente */
}

.booking-modal__optional-coverages label {
  font-size: 1.5rem;
  /* Reducido ligeramente */
  margin-left: 0.8rem;
  /* Reducido ligeramente */
}

.booking-modal__optional-coverages input[type="text"] {
  font-size: 1.5rem;
  /* Reducido ligeramente */
  margin-left: 0.8rem;
  /* Reducido ligeramente */
}

.booking-modal__optional-extras h4 {
  font-size: 1.9rem;
  /* Reducido ligeramente */
  text-transform: uppercase;
  color: #3398db;
  margin-bottom: 1.8rem;
  /* Reducido ligeramente */
}

.booking-modal__optional-extras .option {
  margin-bottom: 0.8rem;
  /* Reducido ligeramente */
}

.booking-modal__optional-extras label {
  font-size: 1.5rem;
  /* Reducido ligeramente */
  margin-left: 0.8rem;
  /* Reducido ligeramente */
}

.booking-modal__optional-extras input[type="text"] {
  font-size: 1.5rem;
  /* Reducido ligeramente */
  margin-left: 0.8rem;
  /* Reducido ligeramente */
}


.booking-modal__recommendations h4 {
  font-size: 2rem;
  text-transform: uppercase;
  color: #3398db;
  margin-bottom: 2rem;
}

.booking-modal__recommendations .option {
  margin-bottom: 0rem;
}

.booking-modal__recommendations label {
  margin-left: 1rem;
  font-size: 1.6rem;
}

.btnFlagEnglish{
  background-image: url('https://www.worldometers.info/img/flags/small/tn_cs-flag.gif');
  background-size: cover;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  text-align: center;
}

.modal-content p {
  margin: 0 0 20px 0;
  color: #333;
}
